.banner-slideshow {
    position: relative;
    width: 100%;
    height: 550px; /* Set the height of the banner slideshow */
    overflow: hidden;
  }
  
  .banner-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .banner-slide.active {
    opacity: 1;
  }
  
  .banner-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: black;
  }
  

  @media screen and (min-width:320px) and (max-width:480px) {
    .banner-slideshow {
      position: relative;
      width: 100%;
      height: 153px; /* Set the height of the banner slideshow */
      overflow: hidden;
    }

    .banner-text {
      position: relative;
      bottom: 20px;
      /* left: 20px; */
      color: black;
      margin-top: 42%;
      display: none;
    }
  }