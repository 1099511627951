/* src/components/RightSideMenu.css */

.right-side-menu {
    position: fixed;
    top: 0;
    right: -300px; /* Hidden by default */
    width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0,0,0,0.3);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.right-side-menu.open {
    right: 0; /* Slide in */
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    /* background-color: #f1f1f1; */
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.menu-content {
    padding: 20px;
    flex: 1;
}

.menu-content ul {
    list-style-type: none;
    padding: 0;
}

.menu-content ul li {
    margin: 15px 0;
}

.menu-content ul li a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    transition: color 0.2s;
}

.menu-content ul li a:hover {
    color: #007BFF;
}

.right-side-menu {
    position: fixed;
    top: 0;
    right: -400px;
    width: 400px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1000;
}

.right-side-menu.open {
    right: 0;
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.menu-content {
    padding: 10px;
    text-align: left;
}

.menu-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-content ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 18px;
    cursor: pointer;
}

.menu-content ul li:hover {
    background-color: #f0f0f0;
}

.menu-content ul li img {
    margin-right: 10px;
}

.menu-content ul li .menu-item-text {
    flex-grow: 1;
}

.menu-content ul li .arrow {
    font-size: 18px;
}

hr {
    border: none;
    border-bottom: 1px solid #ddd;
    margin: 0;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
