.showtimes-container {
  padding: 20px;
}

.date-group {
  margin-bottom: 30px;
}

.movie-date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.theatre-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  background-color: #F1F8FF;
  border-radius: 10px;
}

.theatre-details {
  flex: 1;
  margin-right: 20px;
  margin-top: 20px;
}

.theatre-name {
  font-size: 16px;
  font-weight: 600;
}

.screen-details {
  margin-left: 20px;
}

.screen-name {
  font-size: 14px;
  font-weight: 500;
}

.showtime-slots {
  flex: 2;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.screen-showtimes {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.showtime-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.showtime-button:hover {
  background-color: #ccc;
}


.terms-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
}

.theatre_detailsss{
  display: flex;
  padding: 2% 20%;
  font-size: 20px;
  color: #384DAA;
}

.showtimes-container {
  width: 100%;
}

.date-row {
  display: flex;
  /* justify-content: center; */
  margin-bottom: 20px;
}

.date-button {
  padding: 10px;
  margin: 0 5px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 10px;
}

.date-button.selected {
  background-color: #384daa;
  color: white;
}

.theatre-row {
  margin: 20px 0;
}

.showtime-button {
  margin: 5px;
  padding: 10px;
  background-color: #384daa;
  color: white;
  border: none;
  cursor: pointer;
}



.showtime-slots {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust spacing between buttons */
}

.screen-showtimes {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons in a screen */
  flex-wrap: wrap; /* Wrap to the next row if there are too many buttons */
}

.showtime-button {
  /* display: flex; */
  flex-direction: column; /* Stack showtime and screen name inside the button */
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #384daa;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.showtime-button:hover {
  background-color: #384daa;
}

.button-content {
  text-align: center;
}

.showtime {
  font-weight: bold;
  font-size: 14px;
}

.screen-name {
  font-size: 12px;
  color: #555;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 16%;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-right: 30%;
}

.google-map {
  width: 100%;
  height: 300px;
  border: none;
  margin-top: 10px;
}

.facilities {
  margin-top: 20px;
  text-align: left;
}

.facilities h4 {
  margin-bottom: 10px;
}

.facilities ul {
  list-style-type: disc;
  padding-left: 20px;
}




