.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  border-radius: 10px;
  width: 400px;
  max-height: 500px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.popup-header {
  padding: 10px;
  /* background-color: #6200ea; */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-body {
  padding: 15px;
  flex: 1;
  overflow-y: auto;
}

.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.location-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.location-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.location-item:hover {
  background-color: #f0f0f0;
}
