/* General Container */
.help-support-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: left;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

h3 {
    color: #444;
    margin-bottom: 15px;
}

/* FAQ Section */
.faq-section {
    margin-bottom: 30px;
}

.faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.faq-item h4 {
    color: #222;
    font-weight: bold;
}

.faq-item p {
    color: #666;
    font-size: 0.9rem;
}

/* Contact Form Section */
.contact-form-section {
    margin-bottom: 20px;
}

.contact-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #007bff;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}
