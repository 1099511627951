/* StarRating.css */

.star-rating {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.star {
  font-size: 24px;
  cursor: pointer;
  color: #ccc;
  transition: color 0.2s;
}

.star.selected {
  color: #ffcc00;
}

.review_count{
  margin-top: 18px;
}

.add_co{
  text-align: left;
  padding: 10px;
  margin-left: 75px;
  margin-top: 15px;

}
.submit_button{
  background-color: #16887d;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  color: #ffffff;
  border: #16887d;
  margin-left: 82%;
}
