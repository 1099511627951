.event-details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 75%;
  margin-left: 12%;
}

.seat-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
}

.seat-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.available-count {
  background-color: #f5cc67;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}

.seat-type {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.1rem;
}

.seat-icon {
  width: 24px;
  height: 24px;
}

.add-button {
  background-color: white;
  color: #007bff;
  border: 1px solid #384DAA;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
}

.add-button:hover {
  background-color: #384DAA;
  color: white;
}


.event-banner-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px; /* Adjust height as needed */
}

.event-banner1 {
  transition: transform 0.5s ease-in-out; /* Smooth zoom transition */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-banner-container:hover .event-banner1 {
  transform: scale(1.2); /* Zoom in effect */
}
