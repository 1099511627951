.movie-detail-page {
  width: 100%;
}

.movie-banner {
  display: flex;
  background-size: cover;
  background-position: center;
  padding: 50px;
  color: white;
  position: relative;
}

.movie-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie-image img {
  width: 300px;
  height: auto;
  border-radius: 8px;
}

.movie-info {
  margin-left: 20px;
}

.movie-info h2 {
  font-size: 2.5rem;
}

.movie-info p {
  font-size: 1.2rem;
}

.movie-rating {
  display: flex;
  align-items: center;
}

.stars {
  margin-left: 10px;
  color: yellow;
}

.book-tickets {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #5E5EBD;
  border: none;
  color: white;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
}

.movie-description {
  padding: 20px;
}

.movie-trailer {
  padding: 20px;
}

.movie-cast, .movie-crew {
  padding: 20px;
}

.cast-list, .crew-list {
  display: flex;
  flex-wrap: wrap;
}

.cast-item, .crew-item {
  width: 150px;
  margin: 10px;
  text-align: center;
}

.cast-item img, .crew-item img { 
  width: 138px;
  height: 138px;
  border-radius: 50%;
}

.movie-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 400px; /* Adjust based on your design */
  display: flex;
}

.movie-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(94, 96, 190, 0.7), /* #5E60BE 70% */
    rgba(92, 158, 219, 0.12) /* #5C9EDB 12% */
  );
  z-index: 1;
}

.movie-details-container {
  position: relative;
  z-index: 2; /* Ensure the content is above the gradient */
  display: flex;
  flex-direction: row;
  width: 57%;
  padding: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content select {
  width: 100%;
  margin-bottom: 15px;
}

.modal-content button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #5E60BE;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #4E54A0;
}

/* Modal styling */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  font-size: 22px;
  margin-bottom: 20px;
}

/* Language display */
.language-option {
  background-color: #f1f1f1;
  color: #7d7d7d;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-weight: 500;
}

/* Movie format options */
.movie-format-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.movie_format {
  background-color: #f1f1f1;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  color: #5e5e5e;
}

.movie_format:hover {
  background-color: #5c9edb;
  color: #fff;
}

/* Buttons */
.modal-content button {
  background-color: #5c9edb;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #4a86b7;
}


/* Movie format options */
.movie-format-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.movie_format {
  background-color: #f1f1f1;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  color: #5e5e5e;
}

.movie_format:hover {
  background-color: #5c9edb;
  color: #fff;
}

/* Style for selected format */
.movie_format.selected {
  background-color: #5c9edb;
  color: white;
  border: 2px solid #3c8edb;
}



