.review_detail {
    border: 1px solid #ccc; /* Add border with a color of your choice */
    padding: 10px; /* Add padding for spacing */
    text-align: left; /* Align content to the left */
    margin-bottom: 10px; /* Add margin to separate each review */
    margin-left: 85px;
    margin-right: 85px;
    background-color: #F9F9F9;
    border-radius: 10px;
  }


  .comm_input{
    width: 88.5%;
    border-radius: 10px;
    height: 100px;

  }

  .reating_pro{
    display: flex;
  }

  .pro_fi{
    color: #000000;
    font-family: "Segoe UI-Semibold", Helvetica;
    font-weight: 400;
    font-size: 25px;
    margin-top: 18px;
    width: 10%
  }

  .pro_da{
    text-align: left;
    margin-left: 75%;
    margin-top: 18px;
    color: #8F8F8F;
    font-family: "Segoe UI-Semibold", Helvetica;
    font-weight: 400;
    font-size: 15px;
  }