.event-detail-page {
  font-family: Arial, sans-serif;
}

.event-banner {
  background-size: cover;
  background-position: center;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
}

.event-banner-content {
  text-align: left;
  background-color: #dcd8d880;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  width: 75%;
  margin-left: 12%;
}

.event-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.event-date-time,
.event-location {
  font-size: 1.2rem;
  color: #000000;
  text-align: left;
}

.event-info-section {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  text-align: left;
  width: 75%;
  margin-left: 11%;
}

.event-details,
.event-description {
  width: 30%;
  background-color: #dcd8d880;
  text-align: left;
  border-radius: 10px;
  padding: 10px;
}

.hall-image {
  width: 300px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.action-buttons {
  text-align: center;
  margin-top: 20px;
}

.book-tickets,
.view-only {
  background-color: #384DAA;
  color: white;
  padding: 1px 30px;
  border: none;
  cursor: pointer;
  margin: 5px;
  border-radius: 41px;
  font-size: 1.2rem;
}

.book-tickets:hover,
.view-only:hover {
  background-color: #384DAA;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
