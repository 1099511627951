.event-banner {
  position: relative;
  background-position: center;
}

.event-banner-content {
  color: white;
  padding: 20px;
  text-align: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.seat-selection {
  display: flex;
  flex-wrap: wrap;
}

.seat-button {
  margin: 5px;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.bottom-bar {
  /* position: fixed; */
  bottom: 0;
  width: 75%;
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12%;
}

.book-button {
  background-color: #384DAA;
  color: white;
  padding: 1px 30px;
  border: none;
  cursor: pointer;
  margin: 5px;
  border-radius: 41px;
  font-size: 1.2rem;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 637px;
}

.book-tickets,
.view-only {
  background-color: #384DAA;
  color: white;
  padding: 1px 30px;
  border: none;
  cursor: pointer;
  margin: 5px;
  border-radius: 41px;
  font-size: 1.2rem;
}

.book-tickets:hover,
.view-only:hover {
  background-color: #384DAA;
}
