.addon-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.addon-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.addon-category-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.addon-category-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #efefef;
  font-weight: bold;
  cursor: pointer;
}

.addon-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.addon-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 220px;
  display: flex;
}

.addon-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.addon-info h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.addon-info p {
  font-size: 14px;
  margin: 5px 0;
}

.addon-add-button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.addon-add-button:hover {
  background-color: #0056b3;
}

/* Flexbox container for the addon and payment sections */
.order-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

/* Addon section taking 70% of the space */
.addon-container {
  flex: 0 0 70%;
  margin-right: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.payment-summary {
  flex: 0 0 30%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Other existing CSS */
.addon-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.addon-category-buttons {
  display: flex;
  margin-bottom: 20px;
}

.addon-category-buttons button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: #efefef;
  font-weight: bold;
  cursor: pointer;
}

.addon-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.addon-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  width: 220px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
}

.addon-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.addon-info h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.addon-info p {
  font-size: 14px;
  margin: 5px 0;
}

.addon-add-button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.addon-add-button:hover {
  background-color: #0056b3;
}

.addon-add-button.added {
  background-color: #28a745;
  cursor: not-allowed;
}

.address_list {
  margin: 15px 0;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.notification_list {
  display: flex;
  align-items: center;
}

.notification {
  flex-shrink: 0;
  margin-right: 15px;
}

.noti_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.noti_text {
  flex-grow: 1;
}

.n_text {
  margin: 0;
  /* font-size: 14px; */
  font-weight: 500;
  color: #333;
  text-align: left;
}

.n_date {
  font-size: 12px;
  color: #777;
}

hr {
  border: none;
  border-top: 1px solid #000;
  margin: 10px 0;
}



